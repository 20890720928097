export const primaryContentExperimentName = 'category_page_l1_l2_hero_side_nav'
export const primaryContentExperimentVariationControl = 'control'
export const primaryContentExperimentVariationTest = 'test'
export const primaryContentExperimentVariationTest2 = 'test_2'

interface PrimaryContentTestData {
  NA: string[];
  EU: string[];
}

const primaryContentTestDataRaw: PrimaryContentTestData = {
  NA: [
    'officeSupplies',
    'marketingMaterials',
    'stationery',
    'signsAndPosters',
    'banners',
    'displaySigns',
    'postersRigidSigns',
    'customDecals',
    'labelsAndStickers',
    'singleStickers',
    'labels',
    'clothingAndBags',
    'packaging',
    'promotionalProducts',
    'customTShirts'
  ],
  EU: [
    'printAdvertisingSupplies',
    'marketingMaterials',
    'printStationery',
    'signsAndPosters',
    'banners',
    'flagss',
    'postersRigidSigns',
    'tradeShowDisplays',
    'decals',
    'labelsAndStickers',
    'customLabels',
    'stickersCustom',
    'packaging',
    'shippingPackaging',
    'customfoodpackaging',
    'cardboardPackaging',
    'clothingAndBags',
    'promotionalProducts',
    'officeSupplies',
    'customTShirts'
  ]
}

interface PrimaryContentExperimentData {
  [key: string]: string[];
}

export const primaryContentExperimentData: PrimaryContentExperimentData = {
  'en-US': primaryContentTestDataRaw.NA,
  'es-US': primaryContentTestDataRaw.NA,
  'en-CA': primaryContentTestDataRaw.NA,
  'fr-CA': primaryContentTestDataRaw.NA,
  'en-GB': primaryContentTestDataRaw.EU,
  'de-DE': primaryContentTestDataRaw.EU
}

export const primaryContentExperimentLocales = Object.keys(primaryContentExperimentData)
